@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&display=swap");
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .side-input {
    @apply border-b outline-none focus:border-blue-500 w-11/12;
  }

  .blog-input {
    @apply w-full;
  }

  .primary {
    @apply bg-blue-500 px-12 rounded-[4px] border-none w-6/12 hover:bg-blue-500;
  }

  .secondary {
    @apply rounded-[4px] border-none w-6/12 bg-gray-100 hover:text-white hover:bg-none text-black/80;
  }

  .modal-action {
    @apply w-full;
  }

  .btn-primary {
    @apply bg-[#2136D4] hover:bg-[#2136D4];
  }

  .btn-secondary {
    @apply text-[#2136D4] border-[#2136D4] border hover:bg-transparent hover:border-[#2136D4] bg-white;
  }

  .btn-basic {
    @apply bg-transparent border border-gray-500 text-gray-500 hover:bg-transparent hover:border-gray-500;
  }

  .btn {
    @apply rounded-[4px] text-sm font-normal capitalize;
  }

  .btn-payment {
    @apply bg-[#2136D4] text-base text-center py-1 hover:bg-[#2136D4] text-white border-0 font-semibold tracking-widest w-full;
  }

  .grid-container {
    @apply grid grid-cols-3;
  }

  .flex-item {
    @apply flex gap-4 items-center;
  }

  .nav-link {
    @apply text-black hover:text-black focus:text-black hover:no-underline focus:no-underline text-xl;
  }

  .sidenav-link {
    @apply my-1 hover:bg-gray-100 w-full px-3 cursor-pointer no-underline hover:no-underline focus:no-underline py-1 text-black;
  }

  .grid-container {
    @apply grid grid-cols-3;
  }
  .flex-item {
    @apply flex gap-4 items-center;
  }
  .filled {
    @apply text-[#2136D4];
  }

  .star {
    @apply h-8 w-8;
  }

  .empty {
    @apply text-gray-300;
  }
  .banner-container {
    @apply w-1/3 flex flex-col gap-[5px] items-center justify-center;
  }

  .icon-text {
    @apply text-[.725rem] text-center;
  }
}

/* skeleton css start */

.ebo_skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/* skeleton css end */

/* loader/spinner css start */
.loader {
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #2136d4;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader/spinner css  end */
